<template>
    <section class="start-free-area">
        <div class="container">
            <div class="col-12">
                <!-- Start Free Content -->
                <div class="start-free-content d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between shadow-lg" data-aos="fade-up">
                    <!-- Start Free Content -->
                    <div class="start-free-text">
                        <h2 class="mb-2">Sei dabei!</h2>
                        <span>Jetzt kostenlos mit Bizffair Basic</span>
                    </div>
                    <!-- Start Free Button -->
                    <div class="start-free-btn mt-4 mt-lg-0">
                        <a target="_blank" href="https://share.hsforms.com/13QDJ73XgTV22Cdl61Tqe-Abzrpl" class="btn btn-bordered"><span>Einladungscode anfordern</span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>