<template>
    <section id="about" class="section about-app-area ptb_100">
        <div class="shapes-container">
            <div class="shape-1"></div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Text -->
                    <div class="about-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Über Uns</span>
                            <h2 class="text-capitalize">Warum Bizffair die beste Wahl für Ihr kleines Unternehmen ist</h2>
                        </div>
                        <p class="my-3">Bei Bizffair verstehen wir die Herausforderungen, denen sich kleine Unternehmen gegenübersehen, wenn sie in die Welt des E-Commerce eintreten. Unsere Plattform wurde entwickelt, um diesen Übergang so einfach und effizient wie möglich zu gestalten. Mit Bizffair können Sie nicht nur einen professionellen Online-Shop erstellen, sondern auch Ihre Kundenbindung stärken und Ihren lokalen Markt erweitern.</p>
                        <p class="d-none d-sm-block my-3">Unsere benutzerfreundlichen Tools und umfassenden Support-Dienstleistungen sorgen dafür, dass Sie immer auf dem neuesten Stand bleiben und das Beste aus Ihrem Online-Geschäft herausholen.</p>
                        <!-- Store Buttons -->
                        <div class="btns-crowded button-group store-buttons">
                            <a target="_blank" href="https://share.hsforms.com/13QDJ73XgTV22Cdl61Tqe-Abzrpl" class="btn btn-bordered">
                                <i class="icofont icofont-ticket dsp-tc"></i>
                                <p class="dsp-tc">Jetzt Profil erstellen
                                    <br> <span>Einladungscode anfordern</span></p>
                            </a>
                            <a target="_blank" href="https://bizffair.com" class="btn btn-bordered">
                              <i class="icofont icofont-link dsp-tc"></i>
                              <p class="dsp-tc">Zur Plattform
                                <br> <span>Bizffair erkunden</span></p>
                            </a>
                            <!-- <a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-apple dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Apple Store</span></p>
                            </a> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center d-lg-block about_image_custom">
                        <img src="assets/img/about.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.about_image_custom {
  margin-top: 2rem;
}
@media (min-width: 992px) {
  .about_image_custom {
  margin-top: 0;
}
}
</style>