<template>
    <section class="section download-area ptb_100">
        <!-- Shapes Container -->
        <div class="shapes-container d-none d-sm-block">
            <div class="shape-2"></div>
            <div class="shape-3"></div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <!-- Download Text -->
                    <div class="download-text text-center">
                        <h2>FAQ</h2>
                        <p class="my-3">Haben Sie Fragen zur Nutzung von Bizffair? Unsere Plattform ist sowohl auf Mobilgeräten als auch auf Laptops verfügbar und bietet Ihnen die Flexibilität, die Sie benötigen, um Ihr Geschäft jederzeit und überall zu verwalten.</p>
                        <p class="my-3"><a target="_blank" href="https://bizffair.com/faq" class="btn-link">Möchten Sie mehr erfahren? Besuchen Sie unsere FAQ-Seite.</a></p>
                        <!-- <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p> -->
                        <!-- Store Buttons -->
                        <!-- <div class="button-group store-buttons">
                            <a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                                <p class="dsp-tc">GET IT ON
                                    <br> <span>Google Play</span></p>
                            </a>
                            <a href="#" class="btn btn-bordered">
                                <i class="icofont icofont-brand-apple dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Apple Store</span></p>
                            </a>
                            <a href="#" class="btn btn-bordered d-none d-lg-inline-block">
                                <i class="icofont-brand-windows dsp-tc"></i>
                                <p class="dsp-tc">AVAILABLE ON
                                    <br> <span>Windows Store</span></p>
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <!-- App Screenshot Slider Area -->
                    <div class="app-screenshots">
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/faq_mockup.png" alt="">
                            <!-- Screenshots Overlay -->
                            <!-- <div class="screenshots-overlay">
                                <a href="assets/img/faq_mockup.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div> -->
                        </div>               
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>