<template>
    <section class="section work-area ptb_100">
        <!-- Work Slider Wrapper -->
        <div class="work-wrapper d-none d-md-block">
            <div class="work-slider-wrapper" data-aos="zoom-in">
                <!-- Work Slider -->
                <ul class="work-slider owl-carousel">
                    <li class="slide-item">
                        <img src="assets/img/steps_screenshot_1.png" alt="">
                    </li>
                    <li class="slide-item">
                        <img src="assets/img/steps_screenshot_2.png" alt="">
                    </li>
                    <li class="slide-item">
                        <img src="assets/img/steps_screenshot_3.png" alt="">
                    </li>
                    <li class="slide-item">
                        <img src="assets/img/steps_screenshot_4.png" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center d-md-none">
                <!-- Section Heading -->
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Erste Schritte</h2>
                        <p class="d-none d-sm-block mt-4">Einfache Schritte, um Ihr Unternehmen online zu präsentieren und zu verwalten. Verfügbar auf Mobilgeräten und Laptops.</p>
                        <p class="d-block d-sm-none mt-4">Einfache Schritte zur Einrichtung Ihres Unternehmensprofils. Verfügbar auf Mobilgeräten und Laptops.</p>
                    </div>
                </div>
            </div>
            <!-- Work Content -->
            <div class="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item active">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">1. Bizffair beitreten</h3>
                            <p>Registrieren Sie sich, um Zugang zu den Tools zur Erstellung Ihres Unternehmensprofils zu erhalten.</p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">2. Unternehmen registrieren</h3>
                            <p>Erstellen Sie Ihr Unternehmensprofil auf Bizffair, um Ihre Online-Präsenz zu starten.</p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">3. Unternehmensprofil</h3>
                            <p>Fügen Sie Ihre informationen, Produkte, Beiträge und Flyer hinzu, um sie in Ihrem Unternehmensprofil zu zeigen.</p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">4. Geschafft!</h3>
                            <p>Beginnen Sie mit dem Verkauf und der Kommunikation mit Kunden. Nutzen Sie den QR-Code, um Kunden auf Ihr Online-Profil aufmerksam zu machen.</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>