<template>
    <footer class="section footer-area footer-bg">
        <!-- Footer Top -->
        <div class="footer-top ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Logo -->
                            <a class="navbar-brand" href="#">
                                <img class="logo" src="assets/img/logo_white.svg" alt="">
                            </a>
                            <p class="text-white-50 mt-2 mb-3">Bizffair unterstützt kleine Unternehmen dabei, online erfolgreich zu sein.</p>
                            <!-- Social Icons -->
                            <div class="social-icons d-flex">
                                <!-- <a class="facebook" href="#">
                                    <i class="fab fa-facebook-f"></i>
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a class="twitter" href="#">
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a class="google-plus" href="#">
                                    <i class="fab fa-google-plus-g"></i>
                                    <i class="fab fa-google-plus-g"></i>
                                </a> -->
                                <a class="bizffair" target="_blank" href="https://bizffair.com/@Bizffair">
                                    <img class="fab custom-icon-bizffair" alt="Bizffair Icon">
                                    <img class="fab custom-icon-bizffair" alt="Bizffair Icon">
                                </a>
                                <a class="instagram" target="_blank" href="https://www.instagram.com/bizffair/">
                                    <i class="fab fa-instagram"></i>
                                    <i class="fab fa-instagram"></i>
                                </a>
                                <a class="facebook" target="_blank" href="https://www.facebook.com/Bizffair">
                                    <i class="fab fa-facebook"></i>
                                    <i class="fab fa-facebook"></i>
                                </a>                                
                                <a class="linkedin" target="_blank" href="https://www.linkedin.com/company/bizffair/">
                                    <i class="fab fa-linkedin"></i>
                                    <i class="fab fa-linkedin"></i>
                                </a>
                                <!-- <a class="youtube" target="_blank" href="https://www.youtube.com/@bizffair">
                                    <i class="fab fa-youtube"></i>
                                    <i class="fab fa-youtube"></i>
                                </a> -->
                                
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-lg-3">
                        !-- Footer Items --
                        <div class="footer-items">
                            !-- Footer Title --
                            <h3 class="footer-title text-white mb-2">Useful Links</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#">Home</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">About Us</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Services</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Blog</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Contact</a></li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white mb-2">Produkt-Hilfe</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" target="_blank" href="https://bizffair.com">Plattform</a></li>
                                <li class="py-2"><a class="text-white-50" target="_blank" href="https://bizffair.com/faq">FAQ</a></li>
                                <li class="py-2"><a class="text-white-50" target="_blank" href="https://bizffair.com/data-protection">Datenschutzerklärung</a></li>
                                <li class="py-2"><a class="text-white-50" target="_blank" href="https://bizffair.com/terms-and-conditions">Plattform Kunden-AGB</a></li>
                                <li class="py-2"><a class="text-white-50" target="_blank" href="https://bizffair.com/partner-terms-and-conditions">Plattform Partner-AGB</a></li>
                                <li class="py-2"><a class="text-white-50" target="_blank" href="https://bizffair.com/imprint">Impressum</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white mb-2">Kontakt</h3>
                            <ul>
                              <li class="py-2"><a class="text-white-50" target="_blank" href="https://share.hsforms.com/1i87iB9chQeeVhECjhmCiAAbzrpl">Feedback</a></li>
                              <li class="py-2"><a class="text-white-50" target="_blank" href="https://share.hsforms.com/1qAmiLDZaQYydAVsy9_ROPwbzrpl">Unterstützung</a></li>
                              <li class="py-2"><a class="text-white-50 scroll" href="#contact">Kontakt</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-lg-3">
                        !-- Footer Items --
                        <div class="footer-items">
                            !-- Footer Title --
                            <h3 class="footer-title text-white mb-2">Kontakt</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#">For Windows</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For Linux</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For IOS</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For Android</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">For Mac</a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left text-white-50">&copy; Copyrights 2024 Bizffair. All rights reserved.</div>
                            <!-- Copyright Right -->
                            <div class="copyright-right text-white-50">Made with <i class="icofont-heart-alt"></i> By <a class="text-white-50" href="https://bizffair.com">Bizffair</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>
.custom-icon-bizffair {
    display: inline-block; /* Ensure proper alignment */
    width: 1em; /* Size of Font Awesome icons */
    height: 1em; /* Size of Font Awesome icons */
    background-size: contain; /* Ensure the SVG fits within the element */
    background-repeat: no-repeat; /* Prevent repeating */
    background-position: center; /* Center the SVG */
    fill: currentColor; /* Ensure the icon inherits the text color */
}
.custom-icon-bizffair{
    background-image: url("data:image/svg+xml,%3csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.15475 31.3428L16.5819 4.61865H47.4181L62.8452 31.3428L47.4183 58.0493H16.5817L1.15475 31.3428Z' fill='white' stroke='white' stroke-width='2'/%3e%3cpath d='M17 52V11H33.7451C36.7516 11 39.268 11.4338 41.2941 12.3013C43.3333 13.1688 44.8627 14.3833 45.8824 15.9448C46.915 17.5063 47.4314 19.3148 47.4314 21.3701C47.4314 22.9316 47.1111 24.3263 46.4706 25.5542C45.8301 26.7687 44.9477 27.7764 43.8235 28.5771C42.6993 29.3779 41.3987 29.9385 39.9216 30.2588V30.6592C41.5425 30.7393 43.0392 31.1864 44.4118 32.0005C45.7974 32.8146 46.9085 33.9491 47.7451 35.4038C48.5817 36.8452 49 38.5535 49 40.5288C49 42.731 48.451 44.6995 47.3529 46.4346C46.2549 48.1562 44.6667 49.5176 42.5882 50.5186C40.5098 51.5062 37.9869 52 35.0196 52H17ZM26.7059 44.0122H32.7059C34.8105 44.0122 36.3595 43.6051 37.3529 42.791C38.3595 41.9769 38.8627 40.8424 38.8627 39.3877C38.8627 38.3333 38.6209 37.4258 38.1373 36.665C37.6536 35.891 36.9673 35.297 36.0784 34.8833C35.1895 34.4562 34.1242 34.2427 32.8824 34.2427H26.7059V44.0122ZM26.7059 27.8564H32.0784C33.1373 27.8564 34.0784 27.6763 34.902 27.3159C35.7255 26.9556 36.366 26.4351 36.8235 25.7544C37.2941 25.0737 37.5294 24.2529 37.5294 23.292C37.5294 21.9173 37.0523 20.8363 36.098 20.0488C35.1438 19.2614 33.8562 18.8677 32.2353 18.8677H26.7059V27.8564Z' fill='%230C70D5'/%3e%3c/svg%3e"); /* Path to your SVG */
}
.custom-icon-bizffair g {
    display: none !important;
}
</style>