<template>
    <section id="benifits" class="section benifits-area ptb_100">
        <div class="container">
            <div class="row">
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_2.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Ihr Online-Shop</h3>
                            <p>Schnelle und einfache Erstellung und Verwaltung Ihres professionellen Online-Shops für Ihr Unternehmen (für Handy und Laptop).</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_1.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Kundenbindung</h3>
                            <!-- <h3 class="mb-2">Kundenbindung leicht gemacht</h3> -->
                            <p>Mit Ihren Kunden in Kontakt bleiben, informieren Sie sie über Neuigkeiten, Angebote und bauen Sie langfristige Beziehungen auf.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_3.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Lokal</h3>
                            <p>Entdecken Sie neue Kunden in Ihrer Nähe und stärken Sie Ihre Präsenz auf dem lokalen Markt mit unserer innovativen Plattform.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_4.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Support</h3>
                            <p>Unser Team steht Ihnen zur Seite, um sicherzustellen, dass Sie das Beste aus Ihrer Präsenz auf unserer Plattform herausholen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>