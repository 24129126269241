<template>
    <section id="contact" class="contact-area bg-gray ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center mb-4">
                        <h2 class="text-capitalize">Kontaktieren Sie uns</h2>
                        <p class="d-none d-sm-block mt-4">Haben Sie Fragen oder benötigen Unterstützung? Kontaktieren Sie unser Team über das Kontaktformular oder per E-Mail unter <a href="mailto:support@bizffair.com">support@bizffair.com</a>. Wir sind hier, um Ihnen zu helfen!</p>
                        <p class="d-block d-sm-none mt-4">Haben Sie Fragen oder benötigen Unterstützung? Kontaktieren Sie uns über das Kontaktformular oder per E-Mail unter <a href="mailto:support@bizffair.com">support@bizffair.com</a>.</p>
                    </div>
                </div>
            </div>
            <div class="text-center mb-4">
                <a target="_blank" href="https://share.hsforms.com/1qAmiLDZaQYydAVsy9_ROPwbzrpl" class="btn btn-bordered">Kontaktformular</a>
                <a target="_blank" href="https://share.hsforms.com/1i87iB9chQeeVhECjhmCiAAbzrpl" class="btn btn-bordered ml-2">Feedbackformular</a>
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    !-- Contact Box --
                    <div class="contact-box text-center">
                        !-- Contact Form --
                        <form id="contact-form" method="POST" action="assets/php/mail.php">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="subject" placeholder="Subject" required="required">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <textarea class="form-control" name="message" placeholder="Messages" required="required"></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-bordered mt-3 mt-sm-4" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                        <p class="form-message"></p>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>