<template>
    <section id="features" class="section features-area ptb_100">
        <div class="shapes-container">
            <div class="shape bg-shape"></div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="features-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Funktionen</span>
                            <h2 class="text-capitalize">Alles, was Sie für Ihren Online-Erfolg brauchen</h2>
                        </div>
                        <p class="my-3">Entdecken Sie die leistungsstarken Funktionen von Bizffair, die Ihnen helfen, Ihren Online-Shop zu verwalten und auszubauen – ganz ohne IT-Kenntnisse. Optimieren Sie Ihre Produkte, erstellen Sie Angebote, informieren Sie Ihre Kunden und vieles mehr.</p>
                        <!-- Counter List -->
                        <div class="counter-list">
                            <ul>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-computer"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div class="counter-text">
                                            <!-- <span class="counter d-inline-block mt-3 mb-2">2350</span> -->
                                            <h5 class="mt-3">Computer</h5>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-iphone"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div class="counter-text">
                                            <!-- <span class="counter d-inline-block mt-3 mb-2">1895</span> -->
                                            <h5 class="mt-3">Smartphone</h5>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-qr-code"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div class="counter-text">
                                            <!-- <span class="counter d-inline-block mt-3 mb-2">1580</span> -->
                                            <h5 class="mt-3">Teilbar</h5>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <!-- Single Counter -->
                                    <div class="single-counter px-4 py-3 text-center">
                                        <!-- Counter Icon -->
                                        <div class="counter-icon">
                                            <i class="icofont-shield-alt"></i>
                                        </div>
                                        <!-- Counter Text -->
                                        <div class="counter-text">
                                            <!-- <span class="counter d-inline-block mt-3 mb-2">1358</span> -->
                                            <h5 class="mt-3">Sicher</h5>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                    <!-- Featured Items -->
                    <div class="featured-items">
                        <ul>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_1.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Einfache Verwaltung</h3>
                                        <p>Verwalten Sie Ihren Online-Shop mühelos von Ihrem Laptop oder sogar mobil, ohne technisches Know-how.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_2.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Mehrere Admins</h3>
                                        <p>Fügen Sie mehrere Administratoren hinzu, um die volle Unterstützung zu erhalten, die Sie benötigen.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_3.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>QR-Code</h3>
                                        <p>Nutzen Sie den QR-Code Ihres Unternehmens, um Ihre Kunden auf Ihren Online-Kanal zu bringen.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-3">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3">
                                        <img src="assets/img/icon_4.svg" alt="">
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Umfassende Funktionen</h3>
                                        <p>Erhalten Sie alle Funktionen, um Ihre Produkte zu bewerben, Angebote zu erstellen, Ihre Kunden zu informieren und vieles mehr.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>