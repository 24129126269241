<template>
    <section class="section testimonial-area ptb_100">
        <div class="shapes-container">
            <div class="shape bg-shape"></div>
        </div>
        <div class="container text-center">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 col-md-8">
                    <div class="testimonials owl-carousel">
                        <!-- Single Testimonial -->
                        <div class="single-testimonial p-3 p-md-5">
                            <!-- <img src="assets/img/avatar_1.png" class="mx-auto d-block" alt=""> -->
                            <!-- Client Name -->
                            <h3 class="client-name mt-4 mb-2">Anna Stahl</h3>
                            <!-- Client Address -->
                            <h5 class="client-address fw-4">Frankfurt am Main, Deutschland</h5>
                            <!-- Client Rating -->
                            <div class="client-rating mt-2 mb-3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                            <!-- Client Description -->
                            <div class="client-description">
                                <!-- Client Text -->
                                <div class="client-text">
                                  <p>Als Inhaber eines kleinen Unternehmens schätze ich die Flexibilität von Bizffair. Die Möglichkeit, mein Unternehmen online zu präsentieren und gleichzeitig Kunden vor Ort anzusprechen, ist fantastisch. Ich sehe, dass die Stärke noch höher ist, je mehr Unternehmen es nutzen. Sehr empfehlenswert für jeden, der sein Unternehmen digitalisieren möchte!</p>
                                </div>
                            </div>
                        </div>
                        <!-- Single Testimonial -->
                        <div class="single-testimonial p-3 p-md-5">
                            <!-- <img src="assets/img/avatar_2.png" class="mx-auto d-block" alt=""> -->
                            <!-- Client Name -->
                            <h3 class="client-name mt-4 mb-2">Sarah Wilson</h3>
                            <!-- Client Address -->
                            <h5 class="client-address fw-4">Mainz, Deutschland</h5>
                            <!-- Client Rating -->
                            <div class="client-rating mt-2 mb-3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                            <!-- Client Description -->
                            <div class="client-description">
                                <!-- Client Text -->
                                <div class="client-text">
                                  <p>Die Benutzeroberfläche von Bizffair ist sehr benutzerfreundlich und intuitiv. Ich habe die Plattform während des Entwicklungsprozesses getestet und bin beeindruckt von der schnellen Entwicklung und den nützlichen Funktionen.</p>
                                </div>
                            </div>
                        </div>
                        <!-- Single Testimonial -->
                        <div class="single-testimonial p-3 p-md-5">
                            <!-- <img src="assets/img/avatar_3.png" class="mx-auto d-block" alt=""> -->
                            <!-- Client Name -->
                            <h3 class="client-name mt-4 mb-2">Philipp Krüger</h3>
                            <!-- Client Address -->
                            <h5 class="client-address fw-4">Frankfurt am Main, Deutschland</h5>
                            <!-- Client Rating -->
                            <div class="client-rating mt-2 mb-3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                            </div>
                            <!-- Client Description -->
                            <div class="client-description">
                                <!-- Client Text -->
                                <div class="client-text">
                                    <p>Bizffair bietet eine umfassende Lösung für kleine Unternehmen, um ihre Online-Präsenz zu stärken. Die einfache Einrichtung und Verwaltung meines Unternehmensprofils hat mir viel Zeit gespart. Ich freue mich auf die kommenden Funktionen!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Testimonial Thumb -->
                    <div class="single-testimonial-thumb d-none d-md-block">
                        <!-- Thumb Prev -->
                        <div class="thumb-prev">
                            <!-- <span>
                                <img src="assets/img/avatar_3.png" alt="">
                            </span> -->
                        </div>
                        <!-- Thumb Next -->
                        <div class="thumb-next">
                            <!-- <span>
                                <img src="assets/img/avatar_2.png" alt="">
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>