<template>
    <section id="home" class="section welcome-area d-flex align-items-center">
        <div class="container">
            <div class="row align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7 col-lg-6">
                    <div class="welcome-intro">
                        <h1>Empower Your Local Business with Bizffair</h1>
                        <!-- <h3 class="fw-3 mt-2 mt-sm-3">Creative way to Land your page</h3> -->
                        <p class="my-3">Bei Bizffair glauben wir, dass kleine Unternehmen die besten Tools verdienen, um online erfolgreich zu sein. Unsere Plattform vereinfacht E-Commerce, hilft Ihnen, einen professionellen Online-Shop zu erstellen, Kunden zu binden und Ihren lokalen Markt zu erweitern.</p>
                        <div class="btns-crowded button-group">
                            <a target="_blank" href="https://share.hsforms.com/13QDJ73XgTV22Cdl61Tqe-Abzrpl" class="btn btn-bordered"><span>Einladungscode anfordern</span></a>
                            <a target="_blank" href="https://bizffair.com" class="btn btn-bordered d-sm-inline-block">Bizffair erkunden</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 col-lg-6">
                    <!-- Welcome Thumb -->
                    <div class="welcome-thumb text-center" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                        <img src="assets/img/welcome_mockup_2.png" alt="">
                    </div>
                    <!-- Video Icon -->
                    <!-- <div class="video-icon d-none d-lg-block">
                        <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                            <i class="icofont-ui-play"></i>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>