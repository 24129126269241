<template>
    <section id="pricing" class="section price-plan-area ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Unsere Abonnements</h2>
                        <p class="d-none d-sm-block mt-4">Wählen Sie das Paket, das Ihren Bedürfnissen am besten entspricht. Sie haben die Wahl zwischen unseren kostenlosen Basic-Optionen und dem Premium-Plan, der ab 33€ pro Monat beginnt und je nach Abrechnungszeitraum variieren kann.</p>
                        <p class="d-block d-sm-none mt-4">Wählen Sie das Paket, das Ihren Bedürfnissen am besten entspricht. Basic kostenlos oder Premium ab 33 € pro Monat.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="price-plan-wrapper row justify-content-center">
                    <div class="single-price-plan">
                        <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                            <h4 class="plan-title text-uppercase">
                                Basic
                            </h4>
                            <div class="plan-cost">
                                <span class="plan-price">€0</span>
                                <!-- <span class="plan-type text-uppercase">/month</span> -->
                            </div>
                        </div>
                        <ul class="plan-features pb-3">
                            <li><i class="icofont-close"></i>Angebotspaket inklusive</li>
                            <li><i class="icofont-check"></i>2 Admins erlaubt</li>
                            <li><i class="icofont-check"></i>3 Unternehmens-Kategorien</li>
                            <li><i class="icofont-check"></i>3 Bilder auf Produkten</li>
                            <li><i class="icofont-check"></i>3 Verlinkte Produkte</li>
                            <li><i class="icofont-check"></i>3 Produkte in Verbindung mit Posts</li>
                            <li><i class="icofont-check"></i>8 Produkte pro Angebot</li>
                            <li><i class="icofont-check"></i>7 Tageanzahl pro Angebot</li>
                        </ul>
                        <!-- <div class="plan-select">
                            <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                        </div> -->
                    </div>
                    <!-- <div class="single-price-plan">
                        <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                            <h4 class="plan-title text-uppercase">
                                Basic
                            </h4>
                            <div class="plan-cost">
                                <span class="plan-price">$29</span>
                                <span class="plan-type text-uppercase">/month</span>
                            </div>
                        </div>
                        <ul class="plan-features pb-3">
                            <li><i class="icofont-check"></i>10GB Linux Web Space</li>
                            <li><i class="icofont-check"></i>10 MySQL Databases</li>
                            <li><i class="icofont-check"></i>Unlimited Email</li>
                            <li><i class="icofont-check"></i>500Gb mo Transfer</li>
                            <li><i class="icofont-check"></i>24/7 Tech Support</li>
                            <li><i class="icofont-close"></i>Daily Backups</li>
                        </ul>
                        <div class="plan-select">
                            <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                        </div>
                    </div> -->
                    <div class="single-price-plan featured">
                        <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                            <h4 class="plan-title text-uppercase">
                                Premium 
                            </h4>
                            <div class="plan-cost">
                                <span class="plan-price">€33</span>
                                <span class="plan-type text-uppercase">/Monat</span>
                            </div>
                        </div>
                        <ul class="plan-features pb-3">
                          <li><i class="icofont-check"></i>Angebotspaket inklusive (Planabhängig)</li>
                            <li><i class="icofont-check"></i>5 Admins erlaubt</li>
                            <li><i class="icofont-check"></i>10 Unternehmens-Kategorien</li>
                            <li><i class="icofont-check"></i>7 Bilder auf Produkten</li>
                            <li><i class="icofont-check"></i>5 Verlinkte Produkte</li>
                            <li><i class="icofont-check"></i>5 Produkte in Verbindung mit Posts</li>
                            <li><i class="icofont-check"></i>12 Produkte pro Angebot</li>
                            <li><i class="icofont-check"></i>31 Tageanzahl pro Angebot</li>
                        </ul>
                        <!-- <div class="plan-select">
                            <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                        </div> -->
                    </div>
                    <!-- <div class="single-price-plan">
                        <div class="price-header d-flex align-items-center justify-content-between my-4 px-3">
                            <h4 class="plan-title text-uppercase">
                                Ultra
                            </h4>
                            <div class="plan-cost text-uppercase">
                                <span class="plan-price">$99</span>
                                <span class="plan-type">/month</span>
                            </div>
                        </div>
                        <ul class="plan-features pb-3">
                            <li><i class="icofont-check"></i>100GB Linux Web Space</li>
                            <li><i class="icofont-check"></i>Unlimited Databases</li>
                            <li><i class="icofont-check"></i>Unlimited Email</li>
                            <li><i class="icofont-check"></i>10000Gb mo Transfer</li>
                            <li><i class="icofont-check"></i>24/7 Tech Support</li>
                            <li><i class="icofont-check"></i>Daily Backups</li>
                        </ul>
                        <div class="plan-select">
                            <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                        </div>
                    </div> -->
                  </div>
                  <div class="btns-crowded button-group store-buttons">
                    <a target="_blank" href="https://share.hsforms.com/13QDJ73XgTV22Cdl61Tqe-Abzrpl" class="btn btn-bordered">
                        <i class="icofont icofont-ticket dsp-tc"></i>
                        <p class="dsp-tc">Jetzt Profil erstellen
                            <br> <span>Einladungscode anfordern</span></p>
                    </a>
                    <a target="_blank" href="https://bizffair.com" class="btn btn-bordered">
                        <i class="icofont icofont-link dsp-tc"></i>
                        <p class="dsp-tc">Zur Plattform
                            <br> <span>Bizffair erkunden</span></p>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>