<template>
    <section id="screenshots" class="section screenshots-area ptb_100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading section-heading-near text-center">
                    <h2 class="text-capitalize">Verwalten Sie Ihr Unternehmen einfach und effizient</h2>
                    <p class="d-none d-sm-block mt-4">Mit Bizffair bieten wir eine benutzerfreundliche Admin-Oberfläche, die es Ihnen ermöglicht, Ihr Unternehmen ohne technische Kenntnisse zu verwalten. Dies unterstützt Sie dabei, effizienter zu arbeiten und Ihre Geschäftsprozesse zu optimieren, sodass Sie sich auf das Wesentliche konzentrieren können.</p>
                    <p class="d-block d-sm-none mt-4">Unsere benutzerfreundliche Admin-Oberfläche ermöglicht es Ihnen, Ihr Unternehmen ohne technische Hürden zu verwalten, damit Sie sich auf das Wesentliche konzentrieren können.</p>
                </div>
            </div>
          </div>  
          <div class="row">
                <div class="col-12">
                    <!-- App Screenshot Slider Area -->
                    <div class="app-screenshots">
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_admin_1.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_admin_1.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_admin_2.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_admin_2.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_admin_3.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_admin_3.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_admin_4.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_admin_4.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>                
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.section-heading-near {
  margin-bottom: 30px;
}
</style>