<template>
    <section id="screenshots" class="section screenshots-area ptb_100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading section-heading-near text-center">
                    <h2 class="text-capitalize">Einblick in Bizffair</h2>
                    <p class="d-none d-sm-block mt-4">Entdecken Sie, wie Sie durch Bizffair Ihre Kunden erreichen und mit ihnen in Kontakt treten können. Erleben Sie, wie einfach es ist, Ihre Unternehmenspräsenz zu verwalten und zu optimieren.</p>
                    <p class="d-block d-sm-none mt-4">Erleben Sie, wie einfach es ist, Ihre Unternehmenspräsenz zu verwalten und zu optimieren.</p>
                </div>
            </div>
          </div>  
          <div class="row">
                <div class="col-12">
                    <!-- App Screenshot Slider Area -->
                    <div class="app-screenshots">
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_1.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_1.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_2.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_2.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_3.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_3.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_4.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_4.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_5.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_5.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                        <!-- Single Screenshot Item -->
                        <div class="single-screenshot">
                            <img src="assets/img/screenshot_6.png" alt="">
                            <!-- Screenshots Overlay -->
                            <div class="screenshots-overlay">
                                <a href="assets/img/screenshot_6.png" data-fancybox="images"><i class="icofont-image"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.section-heading-near {
  margin-bottom: 30px;
}
</style>